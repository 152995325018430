var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"Courier Invoices"}},[_c('div',{staticClass:"demo-inline-spacing"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-modal",rawName:"v-b-modal.modal-1",modifiers:{"modal-1":true}}],attrs:{"variant":"primary"}},[_vm._v(" Upload File")])],1),_c('br'),_c('b-modal',{attrs:{"id":"modal-1","title":"Upload File","ok-only":"","ok-title":"Upload"},on:{"ok":_vm.okBtn}},[_c('b-form-group',{attrs:{"label":"Select Courier","label-for":"select_courier"}},[_c('v-select',{key:_vm.componentKey,attrs:{"placeholder":"Select Courier","options":_vm.option,"id":"select_courier"},model:{value:(_vm.selectedCourier),callback:function ($$v) {_vm.selectedCourier=$$v},expression:"selectedCourier"}})],1),_c('b-form-group',{attrs:{"label":"Invoice ID","label-for":"invoice_id"}},[_c('b-form-input',{attrs:{"id":"invoice_id","placeholder":"Enter Invoice ID"},model:{value:(_vm.invoice_id),callback:function ($$v) {_vm.invoice_id=$$v},expression:"invoice_id"}})],1),_c('b-form-group',{attrs:{"label":"Invoice Date","label-for":"invoice_date"}},[_c('b-form-datepicker',{attrs:{"id":"invoice_date","placeholder":"Enter Invoice Date"},model:{value:(_vm.invoice_date),callback:function ($$v) {_vm.invoice_date=$$v},expression:"invoice_date"}})],1),_c('b-form-group',{attrs:{"label":"Invoice File","label-for":"invoice_file"}},[_c('b-form-file',{attrs:{"id":"invoice_file","multiple":"","file-name-formatter":_vm.formatNames},on:{"change":_vm.read}})],1)],1),_c('vue-good-table',{ref:"universal-payment-master-courier-invoices",attrs:{"mode":"remote","columns":_vm.columns,"rows":_vm.rows,"isLoading":_vm.isLoadingProp,"rtl":_vm.direction,"search-options":{
				enabled: true,
				externalQuery: _vm.searchTerm,
			},"totalRows":_vm.totalRecords,"pagination-options":{
				enabled: true,
			},"sort-options":{
				enabled: true,
				initialSortBy: {field: 'courier_payment_date_new', type: 'desc'}
			}},on:{"on-column-filter":_vm.onColumnFilter,"on-sort-change":_vm.onSortChange,"on-row-click":_vm.onRowClick},scopedSlots:_vm._u([{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Showing 1 to ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":_vm.pages},on:{"input":_vm.handlePageChange},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" of "+_vm._s(props.total)+" entries ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"change":_vm.handleChangePage},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])},[_c('div',{attrs:{"slot":"loadingContent"},slot:"loadingContent"},[_c('b-spinner',{ref:"spinner_one",staticClass:"mr-1",staticStyle:{"width":"4rem","height":"4rem"},attrs:{"variant":"primary","type":"grow"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }